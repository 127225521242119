<script setup>

import { useElementSize } from '@vueuse/core'

const cover = ref(null)
const { width } = useElementSize(cover)

const props = defineProps({
  attrs: {
    type: Object,
  },
	type: {
		type: String
	},
	id: {
		type: Number
	},
})

const colors = ref([
	'magenta',
	'gold',
	'lilac',
	'cyan',
	'red',
	'green'
])

const getImage = () => {
	switch (props.type) {
		case 'release':
			return props.attrs.album.attrs.image?.url
		default:
			return props.attrs.image?.url
	}
}

const getColor = () => {
	switch (props.type) {
		case 'article':
			return getRandomColor()
		case 'podcast':
			return 'cyan'
		case 'release':
			return 'green'
		default:
			return ""
	}
}

const getRandomColor = () => {
	return colors.value[Math.floor(Math.random()*colors.value.length)]
}

</script>

<template>
	<NuxtLinkLocale
		class="article aspect-[3/4] relative overflow-hidden text-light flex flex-col fvs600 tracking-[-0.48px]"
		:to="{ name: `${type}-slug`, params: { slug: id } }"
		ref="cover"
	>
		<div class="flex items-end fvs600 tracking-[-0.48px]">
			<NuxtImg preload class="absolute top-0 left-0 min-w-full max-w-full" :src="getImage()" />
		</div>
		<div class="info w-full relative" :class="`p-[${Math.round(width/14)}px]`">
			<div class="relative z-50">
				<span v-if="attrs.published" class="block date text-light text-lg fvs400" :class="`mb-[${Math.round(width/40)}px]`"> {{ useFormatDate(attrs.published) }}</span>
				<h4 class="line-clamp-2 leading-[1.35]" :class="`text-${getColor()} text-[${Math.round(width/16)}px]`" v-html="attrs.title" />
			
				
			</div>
			<span v-if="attrs.perex" class="back z-30 bg-dark8 flex items-start pt-0" :class="`p-[${Math.round(width/14)}px]`">
					<span>
						<h4 class="line-clamp-[9] dark:text-light" :class="`text-[${Math.round(width/16)}px]`" v-html="attrs.perex" />
					</span>
				</span>
		</div>
		
	</NuxtLinkLocale>
</template>

<style lang="scss" scoped>
	h4 {
		word-break: break-word;
	}
	.date {
		font-size: 4.2cqw;
	}
	.info {
		position: absolute;
		bottom: 0;
		z-index: 30;
		transition: .3s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		background-color: theme('colors.dark8');
		
		&::before {
			position: absolute;
			content: '';
			left: 0;
			bottom: 100%;
			width: 100%;
			height: 80px;
			background: linear-gradient(180deg, rgba(47, 47, 45, 0.00) 0%, #2F2F2D 100%);
		}
		&::after {
			position: absolute;
			content: '';
			left: 0;
			top: 98%;
			width: 100%;
			height: 500%;
			background-color: theme('colors.dark8');
		}
	}

	.back {
		position: absolute;
		left: 0;
		background-color: theme('colors.dark8');
		top: 100%;
		transition: .3s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	}

	.article {
		position: relative;
		container-type: size;

		&:hover {
			.back {
				opacity: 1;
				position: absolute;
    		// transform: translate3d(0, calc(-100% + .01cqh), 0);
			}

			.info {
				transform: translate3d(0, calc(100% - 100cqh), 0);

				h4.line-clamp-2 {
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
				}
			}
		}
	}
</style>