<script setup>

import { useElementSize } from '@vueuse/core'

const cover = ref(null)
const { width } = useElementSize(cover)

const props = defineProps({
  attrs: {
    type: Object,
  },
	id: {
		type: Number
	}
})

</script>

<template>
	<NuxtLinkLocale
		ref="cover"
		class="cover relative overflow-hidden aspect-square text-light flex items-end text-[32px] tracking-[-0.48px] fvs600"
		:class="`p-[${Math.round(width/14)}px]`"
		:to="{ name: 'album-slug', params: { slug: id } }"
	>
		<NuxtImg preload class="absolute top-0 left-0 min-w-full min-h-full max-h-full max-w-full object-cover" :src="attrs.image?.url" />
		<span class="back z-30 bg-dark8 flex items-start p-8">
			<span>
				<h4 class="line-clamp-1 text-gold" :class="`text-[${Math.round(width/16)}px]`">{{ attrs.artistdisplay }}</h4>
				<h4 class="line-clamp-1 text-gold mb-[8cqw]" :class="`text-[${Math.round(width/16)}px]`">{{ attrs.title }}</h4>
				<h4 class="line-clamp-[7] dark:text-light" :class="`text-[${Math.round(width/16)}px]`" v-html="attrs.annotation" />
			</span>
		</span>
	</NuxtLinkLocale>
</template>

<style lang="scss" scoped>

	.cover {
		container-type: size;
		&:hover {
			.back {
				opacity: 1;
    		top: 0;
			}
		}
	}

	h4 {
		word-break: break-word;
	}

	.back {
		position: absolute;
		left: 0;
		right: 0;
		height: 100%;
		background-color: theme('colors.dark8');
		top: 100%;
		transition: .3s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	}

</style>